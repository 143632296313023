// ----------------------------------------------------------------------

import { Role } from '../@types/auth';

export default function getRole(id: string): Role {
  const studentArray: string[] = ['15', '14', '12', '13', '4', '6', '16'];
  const guideArray: string[] = ['10', '9', '8', '7', '3', '17', '18', '5', '21'];
  const parentArray: string[] = ['20'];
  const adminArray: string[] = ['2'];

  if (studentArray.includes(id)) {
    return Role.STUDENT;
  } else if (guideArray.includes(id)) {
    return Role.GUIDE;
  } else if (parentArray.includes(id)) {
    return Role.PARENT;
  } else if (adminArray.includes(id)) {
    return Role.ADMIN;
  } else {
    console.error('Role not found for id: ' + id);
    return Role.STUDENT;
  }
}
