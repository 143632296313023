import { UserCredential } from 'firebase/auth';

// ----------------------------------------------------------------------

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum FeatureFlag {
  LearnPage = 'LEARN_PAGE',
  NewCheckChart = 'NEW_CHECK_CHART',
}

export enum Role {
  UNDEFINED = 'Undefined',
  ADMIN = 'Admin',
  STUDENT = 'Student',
  GUIDE = 'Guide',
  PARENT = 'Parent',
}

export enum LevelDisplayName {
  PKBY = 'PKBY',
  LL = 'LL',
  Linc = 'Linc',
  LL_Linc = 'LL & Linc',
  L1 = 'L1',
  L2 = 'L2',
  L1_L2 = 'L1 & L2',
  L3 = 'L3',
  L4 = 'L4',
  Multilevel = 'Multilevel',
}

export const levelMappings: Level[] = [
  {
    displayName: LevelDisplayName.LL,
    lookupString: '0',
    rosterLevel: 'LL',
    guideGroupId: 10,
    studentGroupId: 15,
  },
  {
    displayName: LevelDisplayName.PKBY,
    lookupString: '-1',
    rosterLevel: 'PKBY',
    guideGroupId: 21,
    studentGroupId: 16,
  },
  {
    displayName: LevelDisplayName.Linc,
    lookupString: '0.5',
    rosterLevel: 'Linc',
    guideGroupId: 9,
    studentGroupId: 14,
  },
  {
    displayName: LevelDisplayName.LL_Linc,
    lookupString: '<1',
    guideGroupId: 18,
  },
  {
    displayName: LevelDisplayName.L1,
    lookupString: '1',
    rosterLevel: 'L1',
    guideGroupId: 8,
    studentGroupId: 12,
  },
  {
    displayName: LevelDisplayName.L2,
    lookupString: '2',
    rosterLevel: 'L2',
    guideGroupId: 7,
    studentGroupId: 13,
  },
  {
    displayName: LevelDisplayName.L3,
    lookupString: '3',
    rosterLevel: 'L3',
    guideGroupId: 3,
    studentGroupId: 4,
  },
  {
    displayName: LevelDisplayName.L4,
    lookupString: '>=4',
    rosterLevel: 'L4',
    guideGroupId: 17,
    studentGroupId: 6,
  },
  {
    displayName: LevelDisplayName.Multilevel,
    lookupString: '>=0',
    guideGroupId: 5,
    adminGroupId: 2,
  },
];

export type Level = {
  displayName: LevelDisplayName;
  lookupString: string;
  rosterLevel?: string;
  guideGroupId: number;
  studentGroupId?: number;
  adminGroupId?: number;
};

export type RawUserCustomData = {
  models: string[];
  permissions: string[];
  accessFilters: object;
  groupIds: string[];
  userAttributes: { [key: string]: string };
};

export type AuthUser = null | {
  userId: number;
  name: string;
  image: string;
  email: string;
  userUuid: string;
  rawCustomData: RawUserCustomData;
  alphaAuthAttributes: {
    role: Role;
    levels: Level[];
    campusId: string;
    studentId: string;
    studentGroup: string;
    childStudentIds: string[];
    featureFlags?: string[];
  };
  authorization: {
    [key: string]: {
      tenantId: string;
      name: string;
      roles: Array<string>;
      permissions: Array<string>;
    };
  };
  attributes: {
    ['custom:customName']: string;
    email: string;
    ['custom:customImage']: string;
  };
};

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
};

export type JWTContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: 'jwt';
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  logout: () => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  sendResetLink: (email: string) => Promise<void>;
  resetPassword: (email: string, code: string, newPassword: string) => Promise<void>;
};

export type FirebaseContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: 'firebase';
  login: (email: string, password: string) => Promise<UserCredential>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  logout: () => Promise<void>;
};

export type AWSCognitoContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: 'cognito';
  login: (email: string, password: string) => Promise<unknown>;
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<unknown>;
  logout: VoidFunction;
};

export type Auth0ContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: 'auth0';
  login: () => Promise<void>;
  logout: VoidFunction;
};

export type DecodedBearerToken = {
  userId: number;
  exp: number;
};
